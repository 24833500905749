<template>
  <div class="auth-layout">
    <div class="auth-background"></div>
    <div class="component-aria" id="component-aria" v-if="i18nTranslations">
      <div class="auth-form">
        <div class="form-wrap">
          <div class="form-image" v-if="formSlots.image">
            <slot name="image"></slot>
          </div>
          <div class="mem-font--auth-form__title form-title" v-if="formSlots.title">
            <slot name="title"></slot>
          </div>
          <div class="form-text" v-if="formSlots.subtitle">
            <slot name="subtitle"></slot>
          </div>
          <div class="form-text-fields" v-if="formSlots.form">
            <slot name="form"></slot>
          </div>
          <div v-if="formSlots.buttons">
            <slot name="buttons"></slot>
          </div>
          <div class="form-footer" v-if="formSlots.footer">
            <slot name="footer"></slot>
          </div>

          <!-- default slot-->
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["i18nTranslations"]),
    formSlots() {
      return {
        image: !!this.$slots.image,
        title: !!this.$slots.title,
        subtitle: !!this.$slots.subtitle,
        form: !!this.$slots.form,
        buttons: !!this.$slots.buttons,
        footer: !!this.$slots.footer,
      };
    },
  },
  // watch: {
  //   i18nTranslations(status) {
  //     console.log(status, "transl");
  //   },
  // },
};
</script>
<style lang="scss" scoped>
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.auth-layout {
  --toolbar-height: 80px;

  display: grid;
  grid-template-columns: 1fr 2fr;
  background-color: #191818;
  grid-column-gap: 20px;
  position: relative;
  height: calc(100vh - var(--toolbar-height));
  @media screen and (max-width: 1279px) {
    --toolbar-height: 65px;
  }
  @media screen and (max-width: 499px) {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    height: 100%;
  }
  .auth-background {
    background-image: url("../../assets/auth-landing-image.jpg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    @media screen and (max-width: 499px) {
      display: none;
    }
  }
  .component-aria {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
    @media screen and (max-width: 499px) {
      display: grid;
      grid-template-columns: minmax(auto, 416px);
    }
    .auth-form {
      max-width: 400px;
      width: 100%;
      height: 100%;
      padding: 80px 0;
      display: grid;
      align-items: center;
      animation: fade-in 0.9s;
      @media screen and (max-width: 499px) {
        padding: 32px 25px;
        align-items: start;
      }
      .form-wrap {
        .form-image {
          margin-bottom: 48px;
          @media screen and (max-width: 499px) {
            margin-bottom: 44px;
          }
        }
        .form-title {
          color: #ffffff;
          margin-bottom: 28px;
          display: flex;
          flex-direction: column;
          @media screen and (max-width: 499px) {
            margin-bottom: 16px;
          }
        }
        .form-text {
          display: flex;
          flex-direction: column;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #ffffff;
          @media screen and (max-width: 499px) {
            font-size: 14px;
          }
        }
        .form-text-fields {
          margin: 58px 0 0 0;
          display: flex;
          flex-direction: column;
          @media screen and (max-width: 499px) {
            margin: 28px 0 0 0;
          }
          ::v-deep(.form-input) {
            margin-bottom: 26px;
            @media screen and (max-width: 499px) {
              margin-bottom: 22px;
            }
          }
          // vuetify components
          ::v-deep(.v-label) {
            text-transform: capitalize;
            font-size: 16px;
          }
          ::v-deep(.v-input) {
            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            textarea:-webkit-autofill,
            textarea:-webkit-autofill:hover,
            textarea:-webkit-autofill:focus,
            select:-webkit-autofill,
            select:-webkit-autofill:hover,
            select:-webkit-autofill:focus {
              -webkit-text-fill-color: #ffffff;
              box-shadow: 0 0 0 30px #191818 inset;
              -webkit-box-shadow: 0 0 0 30px #191818 inset;
              background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
              -webkit-background-clip: padding-box; /* for Safari */
              // border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
            }
          }
          ::v-deep(.form-buttons) {
            // margin-bottom: 62px;
            // @media screen and (max-width: 499px) {
            //   margin-bottom: 44px;
            // }
            button {
              min-width: 154px;
              @media screen and (max-width: 499px) {
                width: 100%;
              }
            }
          }
        }
        .form-footer {
          padding-bottom: 44px;

          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          color: rgba(255, 255, 255, 0.4);
          .text-link {
            font-weight: 700;
            margin-left: 5px;
            @include cursorPointer;
          }
        }
      }
    }
  }
}
</style>
